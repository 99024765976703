.not-found {
  @extend %flex-column;

  align-items: center;

  header {
    @extend %flex-center;

    h1 {
      font-size: 10em;
      color: $c-font-dimmer;
      margin: 0 2rem 0 0;
    }

    strong {
      text-transform: uppercase;
      color: $c-font-dimmer;
      font-size: 3em;
      margin-bottom: 1rem;
      display: block;
    }

    em {
      color: $c-font-dim;
      font-size: 1.5em;
      font-style: italic;
      text-decoration: underline;
      display: block;
      margin: -1rem 0 1rem 0;
    }
  }

  .game {
    text-align: center;

    iframe {
      margin: 1em 0 2em 0;
    }
  }

  .or-play,
  .game {
    display: none;

    @media (hover: hover) {
      @media (min-width: at-least($small)) {
        display: inline-block;
      }
    }
  }

  .credits {
    font-size: 0.9em;
  }
}
